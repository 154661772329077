import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

const LangBox = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const dropdownItemClass = 'dropdown-item text-capitalize px-4';

  const lang = process.env.GATSBY_LOCALE || 'es';

  let langs = [];
  switch (props.station.shortcode) {
    case 'ast':
      langs = [
        {
          key: 'en',
          url: '/'
        },
        {
          key: 'es',
          url: `https://aulaenglishtime.com`
        }
      ];
      break;
    default:
      langs = [
        {
          key: 'es',
          url: '/'
        },
        {
          key: 'en',
          url: `https://aulaspanishtime.com`
        }
      ];
  }

  const langItems = langs.map((item) => {
    let classes = `${dropdownItemClass} active`;

    if (item.key !== lang) {
      classes = `${dropdownItemClass}`;
    }

    return <a href={item.url} className={classes} key={item.key}>{item.key}</a>;
  });

  return (
    <div className="col-auto">
      <Dropdown id="lang" isOpen={dropdownOpen} toggle={toggle} size="sm">
        <DropdownToggle caret color="light" className="text-capitalize">{lang}</DropdownToggle>
        <DropdownMenu right>
          {langItems}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default LangBox;
