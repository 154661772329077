import React from 'react';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const SectionCursos = (props) => {

  let [col1, col2, col3] = props.cols;

  return (
    <div id={props.id}>
      {props.header}

      <div className="container without-carousel">
        <div className="row row-cols-3">

          {col1}
          {col2}
          {col3}

        </div>
      </div>

      <div className="container with-carousel">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={5000}
          centerMode={false}
          className=""
          containerClass="carousel-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            tablet: {
              breakpoint: {
                max: 1199,
                min: 767
              },
              items: 2,
              partialVisibilityGutter: 30
            },
            mobile: {
              breakpoint: {
                max: 767,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            }
          }}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
          >

          {col1}

          {col2}

          {col3}

        </Carousel>

      </div>

      {props.footer}
    </div>
  );
}

export default SectionCursos;
