import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SectionBienvenidos from 'aulatime/src/components/SectionBienvenidos';

import DividerLeft from 'aulatime/src/components/DividerLeft';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faGlobeEurope, faUsers } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faChalkboardTeacher, faGlobeEurope, faUsers);

const ChunkBienvenidos = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            freshchat {
              token
              host
              locale
              siteId
              tags
              config {
                headerProperty {
                  appName
                  appLogo
                  backgroundColor
                }
              }
            }
          }
        }
      }
    `
  );
  const fcConfig = data.site.siteMetadata.freshchat;

  return (
    <SectionBienvenidos fcConfig={fcConfig}>
      <div className="container">
        <div className="row">

          <div className="col-lg-10 offset-lg-1 col-12 text-center">
            <h1 className="mb-4">Welcome</h1>
            <h2 className="text-primary">Practice Beats Theory</h2>
            <p className="lead">You can memorize textbooks and complete online quizzes, but you will genuinely learn only by doing.</p>
            <p>That's why <strong>private Spanish classes online</strong> are an excellent way of learning. Switch to <strong>Private</strong> and <strong>Notice The Difference</strong>.</p>
          </div>

        </div>
        <div className="row row-cols-xl-4 row-cols-md-2 row-cols-1 proceso pt-3">
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p>Native Teachers - Focus On Speaking.</p>
              </div>

              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p>Private Lessons - One-To-One Coaching.</p>
              </div>
              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
          <div className="col">

            <div className="card">
              <div className="card-body">
              <p>Language Concepts Explained In English.</p>
              </div>
              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p>100% Flexible Timetable.</p>
              </div>
              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
        </div>
        <div className="row destacados">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12">
            <h3 className="text-center pt-4 mb-3">What We Offer</h3>

            <div className="row row-cols-1">

              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="chalkboard-teacher" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5>Hone your accent.</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="globe-europe" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5>Express yourself as a native would.</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="users" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5>Imitate cultural traits.</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </SectionBienvenidos>
  );
}
export default ChunkBienvenidos;
