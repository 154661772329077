import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { t } from 'ttag';

import ContactForm from 'contactfo';

const ModalContact = (props) => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const header = props.header || t`¡Reserva tu plaza!`;

  return (
    <>
      {props.button(toggle)}
      <Modal isOpen={modal} toggle={toggle} id="modalContact"  className="modal-lg">
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody>
          <ContactForm
            metadata={{...props.postmanpConfig.metadata, ...{ plan: props.plan }}}
            toggleModal={toggle}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalContact;
