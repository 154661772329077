import React from 'react';
import { jt } from 'ttag';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faClock } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faMapMarkerAlt, faPhone, faClock);

const ContactoDatos = (props) => {
  return (
    <div id="contactoDatos">
      <div className="container">

        <div className="row row-cols-1 row-cols-lg-3">
          <div className="col mb-4">
            <div className="card h-100">
              <FontAwesomeIcon icon="map-marker-alt" />
              <div className="card-body">

                <h5>{jt`Dirección`}</h5>
                <p className="mb-0">Avda. Inmaculada Concepción, 7,<br/>
                29631 Arroyo de la Miel, Málaga, {jt`España`}</p>

              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <FontAwesomeIcon icon="phone" />
              <div className="card-body">

                <h5>{jt`Contacto`}</h5>
                <h3>+34 644 80 37 77</h3>
                <p className="pt-3 mb-0"><strong>Email</strong>:<br /><a href={`mailto:${props.email}`}>{props.email}</a></p>

              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <FontAwesomeIcon icon="clock" />
              <div className="card-body">

                <h5>{jt`Horario`}</h5>
                <p className="mb-0"><strong>{jt`Lun. - Vie.`}</strong>:<br/>10:00 - 14:00<br/>17:00 - 21:00</p>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default ContactoDatos;
