import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionCursos from 'aulatime/src/components/SectionCursos';

import Ribbon from 'aulatime/src/components/Ribbon';
import DiagonalLine from 'aulatime/src/components/DiagonalLine';

const ChunkCursos = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'book', section: '#Courses' }
    }
  };

  const button = (onClick) => (
    <button type="button" className="btn btn-lg btn-primary" onClick={onClick}>Book Now!</button>
  );

  const col1 = <div className="col">
    <div className="card card-01">

      <div className="card-header"></div>
      <div className="card-body">
        <h2>Salad</h2>
        <h3>magundi</h3>
        <p>
          Fit for a squire.
        </p>

        <div className="hour-comparison text-center pt-3">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>12 hours</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>15 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€219,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Live, <span className="text-primary font-weight-bold">one-to-one</span> with your tutor, nothing automated.</li>
          <li className="list-group-item">Build your vocabulary</li>
          <li className="list-group-item">Improve your accent</li>
          <li className="list-group-item">Learn to say things the way a native would</li>
          <li className="list-group-item font-weight-bold">Hours are completely flexible and 100% recoverable</li>
        </ul>
        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#Saladmagundi"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
    <Ribbon text="On Offer" altBg />

  </div>

  const col2 = <div className="col">
    <div className="card card-02">

      <div className="card-header"></div>
      <div className="card-body">
        <h2>Infanta and</h2>
        <h3>Castle</h3>
        <p>
          The best of both worlds.
        </p>

        <div className="hour-comparison text-center pt-3">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>16 hours</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>20 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€259,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>
        <ul className="list-group list-group-flush">
        <li className="list-group-item">Live, <span className="text-primary font-weight-bold">one-to-one</span> with your tutor, nothing automated.</li>
        <li className="list-group-item">Build your vocabulary</li>
        <li className="list-group-item">Improve your accent</li>
        <li className="list-group-item">Learn to say things the way a native would</li>
        <li className="list-group-item font-weight-bold">Hours are completely flexible and 100% recoverable</li>
        </ul>
        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#InfantaAndCastle"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
    <Ribbon text="On Offer" altBg />

  </div>

  const col3 = <div className="col">
    <div className="card card-03">

      <div className="card-header"></div>
      <div className="card-body">
        <h2>La ruta a</h2>
        <h3>Caballo</h3>
        <p>
          Let us treat you like the Don.
        </p>

        <div className="hour-comparison text-center pt-3">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <div>
                <h5>25 hours</h5>
              </div>
              <DiagonalLine/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>30 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3">
          <h5>€349,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>
        <ul className="list-group list-group-flush">
        <li className="list-group-item">Live, <span className="text-primary font-weight-bold">one-to-one</span> with your tutor, nothing automated.</li>
        <li className="list-group-item">Build your vocabulary</li>
        <li className="list-group-item">Improve your accent</li>
        <li className="list-group-item">Learn to say things the way a native would</li>
        <li className="list-group-item font-weight-bold">Hours are completely flexible and 100% recoverable</li>
        </ul>
        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#LaRutaACaballo"
          />
        </div>
      </div>
      <div className="card-footer"></div>

    </div>
    <Ribbon text="On Offer" altBg />

  </div>

  const header = <div className="container">
    <div className="row">
      <div className="col text-center pb-2">
        <h1>Courses</h1>
        <p className="lead">Private tuition - one-to-one. Small groups - 1 to 3 students. Personalized programs - naturally engaging - totally practical lessons.</p>
        <p className="mb-0">Spanish for ESO and Bachillerato. All levels, beginner to advanced.</p>
      </div>
    </div>
  </div>

  return (
    <section id="cursos">
      <SectionCursos
        id="cursos-privados"
        header={header}
        cols={[col1, col2, col3]}
      />
    </section>
  );
}

export default ChunkCursos;
