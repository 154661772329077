import React from 'react';

import SectionTestimonios from 'aulatime/src/components/SectionTestimonios';

const items = [
  {
    key: '01',
    quote: <blockquote className="blockquote">
      <p>Their courses opened my eyes to nuances I would not have picked up in a regular course.</p>
      <footer className="blockquote-footer mt-4">George, Fuengirola</footer>
    </blockquote>
  },
  {
    key: '02',
    quote: <blockquote className="blockquote">
      <p>Alma was fantastic. Just finished our 16 hours beginners class. Great results, learnt a lot. Can absolutely recommend them!</p>
      <footer className="blockquote-footer mt-4">Johan, Marbella</footer>
    </blockquote>
  },
  {
    key: '03',
    quote: <blockquote className="blockquote">
      <p>My husband and I say "bravo", the focus on learning techniques is excellent.</p>
      <footer className="blockquote-footer mt-4">Natalie and Ben, Arroyo de la Miel</footer>
    </blockquote>
  },
  {
    key: '04',
    quote: <blockquote className="blockquote">
      <p>Personalized attention, very good customer service.</p>
      <footer className="blockquote-footer mt-4">Tony, Benalmádena</footer>
    </blockquote>
  }
];

const ChunkTestimonios = () => (
  <SectionTestimonios items={items}/>
);

export default ChunkTestimonios;
