import React, {useEffect} from 'react';
import { jt } from 'ttag';

import WhatsAppWidget from 'react-whatsapp-widget'
import './whatsapp.scss'

const WhatsAppChat = () => {

  useEffect(
    () => {
      let chatBox = document.getElementsByClassName('_2iWL7');

      if (chatBox[0] !== undefined) {

        chatBox = chatBox[0];
        let observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.target.style.opacity !== undefined) {

              chatBox.style.zIndex = mutation.target.style.opacity === '1' ? '2147483601' : '7';

            }
          });
        });

        observer.observe(chatBox, { attributes : true, attributeFilter : ['style'] });
      }
    },
    []
  );

  return (
    <div id="whatsAppChat">
      <WhatsAppWidget
        phoneNumber="34644803777"
        textReplyTime={jt`Respondemos normalmente dentro de un día`}
        message={jt`¡Buenos días! ¿Cómo podemos ayudarte?`}
        companyName={jt`Aula English Time`}
        sendButton={jt`Enviar`}
        className="stretched-link"
      />
    </div>
  );
}

export default WhatsAppChat;
