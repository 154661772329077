import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import ModalContact from 'aulatime/src/components/ModalContact';

const SectionBusiness = () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          station {
            metadata {
              domain
              shortcode
            }
          }
        }
      }
      img: file(relativePath: { eq: "img_business.png" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'contact', section: '#Business' }
    }
  };

  return (
    <section id="business">
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="pb-3">Business</h1>
          </div>

        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-7 col-12 text-md-right text-center">

            <p className="lead">If you're needing Spanish to boost your career, we have courses specially made for the <span className="text-primary-2">business</span> world.</p>
            <p>Ask our agent to show you a customized track to get you straight to the core of what you need in your preferred area of work.</p>

            <div className="pt-3">
              <ModalContact
                header="Business, por favor"
                button={(onClick) => (
                  <button type="button" className="btn btn-xl btn-dark" onClick={onClick}>Business, por favor</button>
                )}
                postmanpConfig={postmanpConfig}
              />
            </div>

          </div>

          <div className="col-lg-6 col-md-5 col-12 d-flex">
            <Img
              fixed={data.img.childImageSharp.fixed}
              className="mb-0 mt-5 mt-md-2 mx-auto"
              alt="Business"
            />
          </div>

        </div>
      </div>
    </section>
  );
}

export default SectionBusiness;
