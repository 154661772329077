import React, { useState, useEffect} from 'react';

import DividerLeft from './DividerLeft';
import DividerRight from './DividerRight';

import GoogleMap from './GoogleMap';

const ContactoMapa = (props) => {

  const mapConfig = {
    ...props.googleMapsConfig,
    ...{
      id: 'googleMaps',
      retries: 3,
      interval: 10000
    }
  }

  const [loadMap, setLoadMap] = useState(false);

  useEffect(
    () => {

      // Lazy load at scroll point
      const handleScroll = () => {
        const supportPageOffset = window.pageXOffset !== undefined;
        const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
        const scrollPosition = supportPageOffset ? window.pageYOffset : isCSS1Compat ? window.document.documentElement.scrollTop : window.document.body.scrollTop;

        if(scrollPosition >= 400) {
          setLoadMap(true);

          window.removeEventListener('scroll', handleScroll);
        }
      };

      window.addEventListener('scroll', handleScroll);
    },
    [loadMap]
  );

  return (
    <div id="contactoMapa">
      <DividerRight/>
      {loadMap && (
        <GoogleMap config={mapConfig}/>
      )}
      <DividerLeft/>
    </div>
  );
}

export default ContactoMapa;
