import React from 'react';

const Ribbon = (props) => (
  <div className={`ribbon ribbon-top-left ${props.altBg ? ' alt-bg' : ''}`}>
    <span>
      {props.slug ?
      <a href={props.slug} className="stretched-link">{props.text}</a> :
      props.text}
    </span>
  </div>
);

export default Ribbon;
