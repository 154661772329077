import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionExamenes from 'aulatime/src/components/SectionExamenes';

import LogoDele from '../img/logo_dele.png';
import LogoCI from '../img/logo_instituto-cervantes.png';

const ChunkExams = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'contact', section: '#Cervantes' }
    }
  };

  return (
    <SectionExamenes>
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="pb-3">Exams</h1>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-md-8 col-12 text-md-right text-center">
            <p className="lead">Handling certification is a big deal to us. We don't want you to be in the classroom forever.</p>
            <p>Our tutors will assess your current level of Spanish and structure your course to get you from start to finish in the shortest possible time.</p>
            <div className="pt-3">
              <ModalContact
                button={(onClick) => (
                  <button type="button" className="btn btn-xl btn-primary-2" onClick={onClick}>Book Now!</button>
                )}
                postmanpConfig={postmanpConfig}
              />
            </div>
          </div>

          <div className="col-md-4 col-12 text-md-left text-center">

              <div className="col">
                <a href="https://examenes.cervantes.es/" target="_blank" rel="noopener noreferrer" aria-label="DELE"><img src={LogoDele} alt="DELE" className="img-fluid mt-md-2 mt-5 pl-md-2" /></a>
              </div>
              <div className="col">
                <a href="https://examenes.cervantes.es/" target="_blank" rel="noopener noreferrer" aria-label="Instituto Cervantes"><img src={LogoCI} alt="Instituto Cervantes" className="img-fluid mt-3 pl-md-2" /></a>
              </div>

          </div>
        </div>
      </div>
    </SectionExamenes>
  );
}

export default ChunkExams;
