import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Link } from 'react-scroll';

import SectionDigital from 'aulatime/src/components/SectionDigital';

const ChunkDigital = () => {

  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "img_online.png" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <SectionDigital>
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="text-white">Online Courses</h1>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-5 col-12 d-flex">
            <Img
              fixed={data.img.childImageSharp.fixed}
              className="mb-md-0 mb-4 mt-md-2 mx-auto"
              alt="Aula Spanish Time"
            />
          </div>
          <div className="col-lg-6 col-md-7 col-12 text-md-left text-center">
            <p className="lead text-white">At Aula Spanish Time we offer a full online service so you can do your classes <span className="text-primary-2">100% online</span>.</p>
            <p className="text-white pb-3">We've got all the latest gadgetry set up so you can work in the comfort of your home as if you were having private tuition in one of our classrooms.</p>
            <Link href="#" className="btn btn-xl btn-light" to="contacto" smooth={"easeInOutCubic"} duration={1500} offset={-103}>Get In Touch</Link>
          </div>
        </div>
      </div>
    </SectionDigital>
  );
}

export default ChunkDigital;
