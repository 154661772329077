import React from 'react';
import { Link } from 'react-scroll';
import { jt } from 'ttag';

import ContactoDatos from './ContactoDatos';
import ContactoMapa from './ContactoMapa';

import ContactForm from 'contactfo';

import WhatsAppChat from '../components/WhatsAppChat';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faLaptopHouse);

const SectionContacto = (props) => {

  const linkToForm = <Link href="#" to="contactoForm" smooth={"easeInOutCubic"} duration={1500} offset={-103} key="linkToForm">
    {jt`formulario`}
  </Link>

  return (
    <section id="contacto">

      <div id="contactoInfo">
        <div className="container">

          <div className="col-lg-10 col-12 mx-auto text-center">
            <h1 className="mb-4">{jt`Contáctanos`}</h1>
            <p className="lead">{jt`Estaríamos encantados de atender tu consulta para ayudarte a sacar el título en tiempo récord`}.</p>
            <p>{jt`Te atendemos mediante teléfono, WhatsApp, email, ${linkToForm} incluso también pinchando al botón de Chat a la derecha y hablando a nuestro agente para ser atendido de inmediato`}.</p>
          </div>

        </div>
      </div>

      <div id="contactoBotones">
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-auto">
              <WhatsAppChat/>
            </div>
            <div className="col-auto">
              <div id="gotoForm">
                <Link href="#" to="contactoForm" smooth={"easeInOutCubic"} duration={1500} offset={-103} className="stretched-link">
                  <div className="row align-items-center">
                    <div className="col">{jt`Formulario online`}</div>
                    <div className="col-auto">
                      <FontAwesomeIcon icon="laptop-house" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>

      <ContactoDatos email={props.email}/>

      <ContactoMapa googleMapsConfig={props.googleMapsConfig}/>

      <div id="contactoForm">
        <div className="container">

          <div className="rounded col-lg-8 offset-lg-2 col-12 px-0">
            <ContactForm
              metadata={props.postmanpConfig.metadata}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionContacto;
