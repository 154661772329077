import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import DividerLeft from 'aulatime/src/components/DividerLeft';
import DividerRight from 'aulatime/src/components/DividerRight';

import ModalContact from 'aulatime/src/components/ModalContact';

const SectionBrushUp = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
        img: file(relativePath: { eq: "img_brush-up.png" }) {
          childImageSharp {
            fixed(width: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'contact', section: '#BrushUp' }
    }
  };

  return (
    <section id="brush-up">
      <DividerRight/>

      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="text-white">Brush Up</h1>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-5 col-12 d-flex">
            <Img
              fixed={data.img.childImageSharp.fixed}
              className="mb-md-0 mb-4 mt-md-2 mx-auto"
              alt="Aula Spanish Time"
            />
          </div>
          <div className="col-lg-6 col-md-7 col-12 text-md-left text-center">
            <p className="lead text-white">If you already speak Spanish but would like to continue practicing: <span className="text-primary-2">we've got just the right kind of class for you</span>.</p>
            <p className="text-white pb-3">Designed in <span className="text-primary-2">short but concentrated sessions</span> to work around busy schedules: they will keep you replete with colloquialisms and rapid-fire little treasures- in the exact tone and rhythm as a native would- to either turn heads, or blend right in.</p>

            <div className="pt-3">
              <ModalContact
                header="Find Out More"
                button={(onClick) => (
                  <button type="button" className="btn btn-xl btn-light" onClick={onClick}>Find Out More</button>
                )}
                postmanpConfig={postmanpConfig}
              />
            </div>

          </div>
        </div>
      </div>

      <DividerLeft/>
    </section>
  );
}

export default SectionBrushUp;
