import React from 'react';

import FreshChat from './FreshChat';

const SectionBienvenidos = ({ children, fcConfig }) => {

  return (
    <section id="bienvenidos">
      {children}
      <FreshChat config={fcConfig}/>
    </section>
  );
}
export default SectionBienvenidos;
