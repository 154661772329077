import React, { useState, useEffect, useCallback } from 'react';
import { Alert } from 'reactstrap';
import { jt } from 'ttag';

import { getCookieExists } from './cookies';

const PopupRebajas = (props) => {

  const [open, setOpen] = useState(false);

  const cookiePopupRebajas = 'popupRebajas';

  const storeCollapse = () => {
    const date = new Date(new Date().setMinutes(new Date().getMinutes() + 10)).toUTCString();
    document.cookie = `${cookiePopupRebajas}=false; Expires=${date}; Path=/; Secure; SameSite=Lax`;
  }

  const doCollapsed = useCallback(() => {

    const popup = document.getElementById("popupRebajas");
    popup.classList.add('collapsed');
    popup.classList.remove('collapse');
    popup.removeEventListener('animationend', doCollapsed);

  }, []);

  const doCollapse = useCallback(() => {
    setOpen(false);

    const popup = document.getElementById("popupRebajas");
    popup.classList.remove('open', 'opened', 'collapsed');
    popup.addEventListener('animationend', doCollapsed);
    popup.classList.add('collapse');

  }, [doCollapsed]);

  const storeOpen = () => {
    document.cookie = `${cookiePopupRebajas}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Secure; SameSite=Lax`;
  }

  const doOpened = useCallback(() => {

    const popup = document.getElementById("popupRebajas");
    popup.classList.add('opened');
    popup.classList.remove('open');
    popup.removeEventListener('animationend', doOpened);

  }, []);

  const doOpen = useCallback(() => {
    setOpen(true);

    const popup = document.getElementById("popupRebajas");
    popup.classList.remove('opened', 'collapse', 'collapsed');
    popup.addEventListener('animationend', doOpened);
    popup.classList.add('open');

  }, [doOpened]);

  const toggle = () => {
    if (open) {
      storeCollapse();
      doCollapse();
    } else {
      storeOpen();
      doOpen();
    }
  }

  //
  const privacyVisible = props.privacyState[0];

  // ~/aulatime/src/layouts/scss/_privacy.scss
  // `overlapWidth` must correspond with `min-width: 500px`
  const overlapWidth = 576;

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: null,
    windowHeight: null
  });

  const getWindowDimensions = () => {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

    return {
      windowWidth,
      windowHeight
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);

    setWindowDimensions(getWindowDimensions());

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    let timer;
    if (!getCookieExists(cookiePopupRebajas)) {

      if (windowDimensions.windowWidth >= overlapWidth) {

        if (!open) {

          timer = setTimeout(() => {
            doOpen();
          }, 2000);

        }

      } else {

        if (privacyVisible) {

          if (open) {

            timer = setTimeout(() => {
              doCollapse();
            }, 5000);

          }

        } else {

          if (!open) {

            timer = setTimeout(() => {
              doOpen();
            }, 2000);

          }

        }
      }
    }

    return () => clearTimeout(timer);

  }, [windowDimensions.windowWidth, privacyVisible, open, doOpen, doCollapse]);

  return (
    <Alert id="popupRebajas" color="dark" isOpen={true} toggle={toggle} fade={false}>
      <div className="caret">
        <button className="btn btn-sm btn-dark" onClick={toggle} aria-label={jt`Abrir`}></button>
      </div>
      <div className="body">
        {props.body}
      </div>
    </Alert>
  );
}

export default PopupRebajas;
