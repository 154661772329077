import { t } from 'ttag';

const GoogleMapPaint = ({ config, callback }) => {

  const sharedOptions = {
    center: config.coords,
    zoom: 18,
    disableDefaultUI: true,
    scrollwheel: false,
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_CENTER
    },
  };

  const mapElem = document.getElementById("mapa");
  const map = new window.google.maps.Map(mapElem, {
    ...sharedOptions,
    mapId: config.mapid,
    useStaticMap: false
  });

  if (callback) {
    window.google.maps.event.addListener(map, 'tilesloaded', () => {
      callback();
    });
  }

  const marker = new window.google.maps.Marker({
    title: 'Aula Time',
    position: config.coords,
    map: map,
    icon: {
      url: `data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20viewBox%3D%220%200%20425.963%20425.963%22%20preserveAspectRatio%3D%27xMidYMid%20meet%27%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%09%3Cpath%20fill%3D%22%23${config.markerColor}%22%20d%3D%22M213.285%2C0h-0.608C139.114%2C0%2C79.268%2C59.826%2C79.268%2C133.361c0%2C48.202%2C21.952%2C111.817%2C65.246%2C189.081%0A%09%09c32.098%2C57.281%2C64.646%2C101.152%2C64.972%2C101.588c0.906%2C1.217%2C2.334%2C1.934%2C3.847%2C1.934c0.043%2C0%2C0.087%2C0%2C0.13-0.002%0A%09%09c1.561-0.043%2C3.002-0.842%2C3.868-2.143c0.321-0.486%2C32.637-49.287%2C64.517-108.976c43.03-80.563%2C64.848-141.624%2C64.848-181.482%0A%09%09C346.693%2C59.825%2C286.846%2C0%2C213.285%2C0z%20M274.865%2C136.62c0%2C34.124-27.761%2C61.884-61.885%2C61.884%0A%09%09c-34.123%2C0-61.884-27.761-61.884-61.884s27.761-61.884%2C61.884-61.884C247.104%2C74.736%2C274.865%2C102.497%2C274.865%2C136.62z%22%2F%3E%0A%3C%2Fsvg%3E`,
      anchor: new window.google.maps.Point(32,32),
      scaledSize: new window.google.maps.Size(48,48)
    }
  });

  const es = t`España`;
  const content = `<div>
<h6 style="color: #1f1f1f1;">Aula Time</h6>
<p>Avda. Inmaculada Concepción, 7,<br/>
29631 Arroyo de la Miel, Málaga, ${es}</p>
</div>`;

  const infoWindow = new window.google.maps.InfoWindow({
    content: content,
    maxWidth: 220
  });

  marker.addListener('click', function() {
    infoWindow.open(map, marker);
  });
}

export default GoogleMapPaint;
