import React, { useState } from 'react';

import DividerLeft from './DividerLeft';
import DividerRight from './DividerRight';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faQuoteLeft, faQuoteRight);

const SectionTestimonios = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = props.items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >

        <div className="container text-center">
          <div className="col-12">
            {item.quote}
          </div>
        </div>

      </CarouselItem>
    );
  });

  const quotes = props.items.map((item) => {
    return (
      <div className="container" key={item.key}>
        <div className="col-12 text-center">
          {item.quote}
        </div>
      </div>
    );
  });

  return (
    <section id="testimonios">
      <DividerRight/>

      <div className="casing">
        <div className="back">
          <div className="container">
            <div className="opening">
              <FontAwesomeIcon icon="quote-left"/>
            </div>
            <div className="closing">
              <FontAwesomeIcon icon="quote-right"/>
            </div>
            {quotes}
          </div>
        </div>

        <div className="front">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            pause={'hover'}
            interval="5000"
          >
            <CarouselIndicators items={props.items} activeIndex={activeIndex} onClickHandler={goToIndex} />

            {slides}

            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Carousel>
        </div>
      </div>

      <DividerLeft/>
    </section>
  );
}

export default SectionTestimonios;
