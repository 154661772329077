import React from 'react';

import DividerLeft from './DividerLeft';
import DividerRight from './DividerRight';

const SectionDigital = ({ children }) => (
  <section id="digital">
    <DividerRight/>
    {children}
    <DividerLeft/>
  </section>
);

export default SectionDigital;
